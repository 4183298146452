import moment from 'moment';

import type { TrelloWindow } from '@trello/window-types';

declare const window: TrelloWindow;

import { makeYearAwareCalendar } from './makeYearAwareCalendar';

moment.locale(window.locale, {
  months: [
    'januar',
    'februar',
    'mars',
    'april',
    'mai',
    'juni',
    'juli',
    'august',
    'september',
    'oktober',
    'november',
    'desember',
  ],
  monthsShort: [
    'jan',
    'feb',
    'mar',
    'apr',
    'mai',
    'jun',
    'jul',
    'aug',
    'sep',
    'okt',
    'nov',
    'des',
  ],
  weekdays: [
    'søndag',
    'mandag',
    'tirsdag',
    'onsdag',
    'torsdag',
    'fredag',
    'lørdag',
  ],
  weekdaysShort: ['søn', 'man', 'tirs', 'ons', 'tors', 'fre', 'lør'],
  weekdaysMin: ['sø', 'ma', 'ti', 'on', 'to', 'fr', 'lø'],
  longDateFormat: {
    LT: 'H.mm',
    LTS: 'H.mm.ss',
    L: 'DD.MM.YYYY',
    LL: 'D. MMMM YYYY',
    LLL: 'D. MMMM YYYY [kl.] H.mm',
    LLLL: 'D. MMMM',
  },
  // see `app/src/components/DateRangePicker/getLocalizedPlaceholder.ts`
  placeholder: {
    l: 'D.M.ÅÅÅÅ',
    LT: 'T:mm',
  },
  calendar: makeYearAwareCalendar({
    sameDay: '[i dag kl.] LT',
    nextDay: '[i morgen kl.] LT',
    nextWeek: 'dddd [kl.] LT',
    lastDay: '[i går kl.] LT',
    lastWeek: '[forrige] dddd [kl.] LT',
    sameYear: 'llll [kl.] LT',
    sameElse: 'll [kl.] LT',
  }),
  relativeTime: {
    future: 'om %s',
    past: 'for %s siden',
    s: 'noen sekunder',
    m: 'ett minutt',
    mm: '%d minutter',
    h: 'en time',
    hh: '%d timer',
    d: 'en dag',
    dd: '%d dager',
    M: 'en måned',
    MM: '%d måneder',
    y: 'ett år',
    yy: '%d år',
  },
  ordinalParse: /\d{1,2}\./,
  ordinal: () => '%d.',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});
